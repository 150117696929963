import { Component, OnInit, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, zip } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  AuthService,
  MsAuthState,
  SubscriptionsManager,
} from '@ms/angular-workspace/dist/core';
import { MenuItem } from 'primeng/api';
import { environment } from '../environments/environment';
import { LookupsState } from './modules/secure/store/lookups.state';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { NavigationEnd, Router } from '@angular/router';
import { IState } from './modules/secure/models';
import { UserState } from './modules/secure/store/user.state';
import { Emittable, EmitterService } from '@ngxs-labs/emitter';
import { LenderState } from './modules/secure/store/lender.state';

declare global {
  interface Window {
    Appcues: any;
  }
}

@Component({
  selector: 'mi-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends SubscriptionsManager implements OnInit {
  topNavBarItems: MenuItem[] = [
    {
      label: 'Property Search',
      routerLink: '/secure/property-search',
    },
    {
      label: 'Workspace',
      routerLink: '/secure/workspace',
    },
    {
      label: 'Market Insights',
      routerLink: '/secure/market-insights',
    },
    {
      label: 'Tenant Research',
      routerLink: '/secure/tenant-research',
    },
    {
      label: 'Report Builder',
      routerLink: '/secure/report-builder',
    },
  ];
  public productUrls = environment.productUrls;
  user: any;

  private _states$: Observable<IState[]> = inject(Store).select(
    LookupsState.states
  );
  private _userProfile$: Observable<any> = inject(Store).select(
    MsAuthState.user
  );
  private loadLookups: Emittable<void> = inject(EmitterService).action(
    LookupsState.load
  );
  private setUserStates: Emittable<IState[]> = inject(EmitterService).action(
    UserState.setUserAvailableStates
  );
  private getLenderMetadata: Emittable<void> = inject(EmitterService).action(
    LenderState.getMetadata
  );

  constructor(
    private authService: AuthService,
    private $gaService: GoogleAnalyticsService,
    public router: Router,
    private store: Store
  ) {
    super();
  }

  ngOnInit() {
    this.subs = this._userProfile$
      .pipe(filter((userProfile) => !!userProfile))
      .subscribe((userProfile) => {
        this.loadLookups.emit();
        this.user = userProfile;
        this.initAppcuesUser();

        if (userProfile.tenant?.clientType?.id == 2) {
          this.getLenderMetadata.emit();
        }
      });

    this.subs = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.$gaService.pageView(
          event.urlAfterRedirects,
          `Page view ${event.urlAfterRedirects}`,
          undefined,
          {
            user_email: this.user.email,
            organization_name: this.user.tenantName,
            invited_date:
              this.user.invitedDate != null
                ? new Date(this.user.invitedDate).toLocaleDateString('en-US')
                : null,
          }
        );
      }
    });

    this.subs = zip([this._states$, this._userProfile$])
      .pipe(filter((_, userProfile) => !!userProfile))
      .subscribe((values) => {
        let availableStates = values[0].filter((x) =>
          values[1].tenantStates.some((y) => y.stateId == x.id)
        );
        this.setUserStates.emit(availableStates);
      });

    this.authService.runInitialLoginSequence();
  }

  initAppcuesUser() {
    if (!this.user?.appCuesUserId) {
      return;
    }

    window.Appcues.identify(this.user.appCuesUserId, {
      first_name: this.user.firstName,
      last_name: this.user.lastName,
      full_name: `${this.user.firstName} ${this.user.lastName}`,
      email: this.user.email,
      organization: this.user.tenant?.name,
      organization_id: this.user.tenant?.id,
      organization_status: this.user.tenant?.isSubscriptionActive
        ? 'Active'
        : 'Inactive',
      organization_type: this.user.tenant?.clientType?.name,
      subscription_tier: this.user.tenant?.subscription?.name,
      state_access: this.user.tenant?.states.join(', '),
      status: this.user.status,
      created_date: this.user.createdDate,
      invited_date: this.user.invitedDate,
      last_login_date: this.user.lastLoginDate,
    });
  }
}
