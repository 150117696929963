import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsComponentsModule } from '@ms/angular-workspace/dist/components';
import {
  MsAuthModule,
  MsAuthNgxsStoreModule,
  MsCoreModule,
} from '@ms/angular-workspace/dist/core';
import { NgxsEmitPluginModule } from '@ngxs-labs/emitter';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MiPermissionsGuard } from './guards/mi-permissions.guard';
import { ngxsOptions, ngxsStates } from './ngxs.config';
import { LookupsState } from './modules/secure/store/lookups.state';
import { BrowserState } from './modules/secure/store/browser.state';
import { AnalyticsLayoutComponent } from './components/analytics-layout/analytics-layout.component';
import { ServerErrorInterceptor } from './interceptors/server-error.interceptor';
import { ToastModule } from 'primeng/toast';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { MiPasswordExpiredGuard } from './guards/mi-password-expired.guard';
import { LenderGuard } from './guards/lender.guard';
import { UserState } from './modules/secure/store/user.state';
import { LenderState } from './modules/secure/store/lender.state';

@NgModule({
  declarations: [AppComponent, AnalyticsLayoutComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    //
    AppRoutingModule,
    //
    MsCoreModule.forRoot({
      services: {
        auth: {
          url: `${environment.oidc.issuer}${environment.oidc.apiPrefix}`,
        },
      },
    }),
    MsAuthModule.forRoot({
      ...environment.oidc,
      products: environment.productUrls,
      url: environment.productUrls.authUrl,
      params: { app: 'mi' },
    }),
    MsAuthNgxsStoreModule.forRoot(),
    MsComponentsModule,
    //
    NgxsModule.forRoot(
      [...ngxsStates, LookupsState, BrowserState, UserState, LenderState],
      ngxsOptions
    ),
    NgxsStoragePluginModule.forRoot({ key: BrowserState }),
    NgxsRouterPluginModule.forRoot(),
    NgxsEmitPluginModule.forRoot(),
    ...environment.ngxs.plugins,
    ToastModule,
    NgxGoogleAnalyticsModule.forRoot(environment.gaId),
    NgxGoogleAnalyticsRouterModule,
  ],
  exports: [ToastModule],
  bootstrap: [AppComponent],
  providers: [
    DialogService,
    MessageService,
    ConfirmationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
    MiPermissionsGuard,
    MiPasswordExpiredGuard,
    LenderGuard,
  ],
})
export class AppModule {}
