import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FeatureApiClientBase,
  ApiGetResponse,
} from '@ms/angular-workspace/dist/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Metadata } from '../models';

@Injectable({
  providedIn: 'root',
})
export class LenderService extends FeatureApiClientBase<any> {
  constructor(private httpClient: HttpClient) {
    super(httpClient, 'lender');
  }

  getMetadata(): Observable<Metadata> {
    return this.httpClient
      .get<ApiGetResponse<Metadata>>(`${this.url()}/metadata`)
      .pipe(map((response) => response.data));
  }
}
