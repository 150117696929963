import { State, StateContext, Selector } from '@ngxs/store';
import { Injectable, Injector } from '@angular/core';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { LenderService } from '../services/lender.service';
import { Metadata } from '../models';

@Injectable()
export class LenderStateModel {
  metadata: Metadata;
}
@State<LenderStateModel>({
  name: 'lender',
  defaults: {
    metadata: {
      propertyTypes: [],
      states: [],
    },
  },
})
@Injectable()
export class LenderState {
  private static lenderService: LenderService;

  constructor(injector: Injector) {
    LenderState.lenderService = injector.get<LenderService>(LenderService);
  }

  @Selector()
  static propertyTypes(state: LenderStateModel) {
    return state.metadata?.propertyTypes;
  }

  @Selector()
  static states(state: LenderStateModel) {
    return state.metadata?.states;
  }

  @Receiver({ type: '[Lender] Get Metadata' })
  public static getMetadata(
    context: StateContext<LenderStateModel>,
    action: EmitterAction<void>
  ) {
    return this.lenderService.getMetadata().pipe(
      tap((metadata) => {
        context.patchState({
          metadata,
        });
      }),
      catchError((response) => throwError(response.error))
    );
  }
}
